import { saveCookie } from '../util/save-cookie';

/**
 * Handles the newsletter signup form submission.
 * Prevents the default form submission, hides the form, and displays a success message upon successful signup.
 * Sends the signup data using the Fetch API and optionally logs the event to `window.dataLayer`.
 *
 * @param {Event} e - The form submission event.
 */
export const initNewsletter = () => {
    document.addEventListener('submit', async e => {
        if (!e.target.matches('.js-newsletter-signup')) {
            return;
        }

        e.preventDefault();

        saveCookie('newsletter-signup', 1, 365);

        const form = e.target;
        const actionUrl = form.getAttribute('action');
        const formData = new URLSearchParams(new FormData(form));

        try {
            const response = await fetch(actionUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const { payload } = await response.json()

                form.querySelector('[data-active]').hidden = true;
                form.querySelector('[data-success]').classList.add(
                    'newsletter-signup__success--active'
                );

                if (payload.success && window.dataLayer) {
                    window.dataLayer.push({ event: 'signup' });
                    window.Mundy.gtmTrackEvent('popup', 'submit', 'subscription', 2)
                }
            } else {
                console.error('There was an error with the signup.');
            }
        } catch (error) {
            console.error('There was an error with the signup.', error);
        }
    });
};
