/**
 * Saves a cookie with the specified name, value, and expiration days.
 * Also stores the value in the global `window` object as a fallback if cookies are blocked.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value to store in the cookie.
 * @param {number} [days] - The number of days until the cookie expires. If not provided, the cookie will be a session cookie.
 */
export const saveCookie = (name, value, days) => {
    window[name] = value; // Just in case cookies are blocked

    let expires = '';

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
};
