import { saveCookie } from '../util/save-cookie';

const popup = document.querySelector('[data-newsletter-popup]');
const closeBtn = popup?.querySelector('[data-close-popup]');
const openTrigger = popup?.querySelector('label');

/**
 * Opens the newsletter popup by adding the active class.
 */
const handleNewsletterOpen = () =>
    popup.classList.add('newsletter-popup--active');

/**
 * Closes the newsletter popup by removing the active class.
 */
const handleNewsletterClose = () =>
    popup.classList.remove('newsletter-popup--active');

const initListenForConsent = () => {
    window.addEventListener('OTConsentApplied', () => {
        popup.classList.add('newsletter-popup--cookie-closed');
    });
};

/**
 * Submits the newsletter form using Fetch API and handles the response.
 * @param {HTMLFormElement} form - The newsletter form element.
 */
const submitNewsletterForm = async form => {
    const actionUrl = form.getAttribute('action');
    const formData = new URLSearchParams(new FormData(form));

    try {
        const response = await fetch(actionUrl, {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            const { payload } = await response.json()

            saveCookie('newsletter-signup', 1, 365);
            popup.innerHTML = 'Thanks for signing up!';

            if (payload.success && window.dataLayer) {
                window.dataLayer.push({ event: 'signup' });
                window.Mundy.gtmTrackEvent('popup', 'submit', 'subscription', 2)
            }
        } else {
            throw new Error('Signup failed');
        }
    } catch (error) {
        console.error('Error during signup:', error);
    }
};

/**
 * Initializes the newsletter popup component.
 */
export const initNewsletterPopup = () => {
    if (!popup) {
        return;
    }

    initListenForConsent();

    closeBtn?.addEventListener('click', handleNewsletterClose);
    openTrigger?.addEventListener('click', handleNewsletterOpen);

    document.addEventListener('submit', e => {
        if (e.target.matches('[data-newsletter-popup] form')) {
            e.preventDefault();
            submitNewsletterForm(e.target);
        }
    });
};
